import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "../components/Skeleton";
import { api } from "../services/api";
import { currencyFormat } from "../utils/currencyFormat";
import { documentFormatted } from "../utils/documentFormatted";
import { CheckIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ApproveModal } from "../components/approve-modal";
import { RejectModal } from "../components/reject-modal";

export interface AccountProps {
  id: string
  clientId: string
  name: string
  document: string
  email: string
  monthlyIncome: number
  publiclyExposedPerson: number
  birthDate: string
  type: string
  accountKey?: string
  bank: any
  bankBranch: any
  bankAccount: any
  bankAccountDigit: any
  status: string
  balance: number
  accountStatus: number
  accountConditionType: number
  originalBalance: number
  feePixIn: number
  feePixOut: number
  feeTedOut: number
  feeP2pOut: number
  feeP2pIn: number
  feeBillet: number
  havePermission: boolean
  phone: string
  createdAt: string
  updatedAt: string
}

export interface MetaProps {
  current_page: number
  total_pages: number
  total_items: number
  total_items_page: number
}

export function Accounts() {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<AccountProps[]>([] as AccountProps[]);
  const [meta, setMeta] = useState<MetaProps>({} as MetaProps);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceIugu, setBalanceIugu] = useState(0);
  const [open, setOpen] = useState(false);
  const [responsibleName, setResponsibleName] = useState('');
  const [responsibleDocument, setResponsibleDocument] = useState('');
  const [accountId, setAccountId] = useState('');
  const [reject, setReject] = useState(false);
  const [openReject, setOpenReject] = useState(false);


  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api.get(`/backoffice/accounts?page=${currentPage}`).then(({ data }) => {
      setAccounts(data.items);
      setBalance(data.sum?.sum ?? 0);
      setBalanceIugu(data?.iuguBalance ?? 0);
      setMeta({ ...data, items: undefined });
      setTotalPages(data.total_pages + 1);
      setCurrentPage(data.current_page);
      setLoading(false);
    })
  }, [currentPage]);


  function getStatus(status: string) {
    if (status === 'ACTIVE') {
      return {
        label: "Ativo",
        color: "green",
      }
    }

    if (status === "BLOCKED") {
      return {
        label: "Bloqueado",
        color: "red"
      }
    }

    if (status === 'WAITING_ANALYSIS') {
      return {
        label: "Aguardando análise",
        color: "gray",
      }
    }

    if (status === 'UNDER_ANALYSIS') {
      return {
        label: "Em análise",
        color: "gray"
      }
    }

    if (status === 'WAITING_DOCUMENTS') {
      return {
        label: "Aguardando documentos",
        color: "gray",
      }
    }

    if (status === 'WAITING_ADDRESS') {
      return {
        label: "Aguardando endereço",
        color: "gray"
      }
    }

    return {
      label: status,
      color: "green"
    }
  }


  function handlePrevious() {
    if (currentPage + 1 > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleNext() {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }


  function handleApprove(id: string, document: string) {
    setLoading(true);
    window.scrollTo(0, 0);
    setAccountId(id);

    if (document.length > 11) {
      setOpen(true);
      setAccountId(id);
    } else {
      console.log(`/backoffice/approve-account/${id}`);

      api.post(`/backoffice/approve-account/${id}`).catch((error) => {
        throw new Error(error);
      })

      api.get(`/backoffice/accounts?page=${currentPage}`).then(({ data }) => {
        setAccounts(data.items);
        setBalance(data.sum?.sum ?? 0);
        setBalanceIugu(data?.iuguBalance ?? 0);
        setMeta({ ...data, items: undefined });
        setTotalPages(data.total_pages + 1);
        setCurrentPage(data.current_page);
        setLoading(false);
      })
    }
  }

  function finalizeApprove() {
    setOpen(false);

    const data = {
      responsibleName,
      responsibleDocument,
    }

    api.post(`/backoffice/approve-account/${accountId}`, data).catch((error) => {
      throw new Error(error);
    })

    api.get(`/backoffice/accounts?page=${currentPage}`).then(({ data }) => {
      setAccounts(data.items);
      setBalance(data.sum?.sum ?? 0);
      setBalanceIugu(data?.iuguBalance ?? 0);
      setMeta({ ...data, items: undefined });
      setTotalPages(data.total_pages + 1);
      setCurrentPage(data.current_page);
      setLoading(false);
    })
  }



  function handleReject(id: string) {
    setLoading(true);
    window.scrollTo(0, 0);
    setOpenReject(true);
    setAccountId(id);
  }

  function finalizeReject() {
    if (reject) {
      api.post(`/backoffice/reject-account/${accountId}`).catch((error) => {
        throw new Error(error);
      })
      api.get(`/backoffice/accounts?page=${currentPage}`).then(({ data }) => {
        setAccounts(data.items);
        setBalance(data.sum?.sum ?? 0);
        setBalanceIugu(data?.iuguBalance ?? 0);
        setMeta({ ...data, items: undefined });
        setTotalPages(data.total_pages + 1);
        setCurrentPage(data.current_page);
        setLoading(false);
      })
      setOpenReject(false);
    }
    api.get(`/backoffice/accounts?page=${currentPage}`).then(({ data }) => {
      setAccounts(data.items);
      setBalance(data.sum?.sum ?? 0);
      setBalanceIugu(data?.iuguBalance ?? 0);
      setMeta({ ...data, items: undefined });
      setTotalPages(data.total_pages + 1);
      setCurrentPage(data.current_page);
      setLoading(false);
    })
    setOpenReject(false);
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Contas</h1>
          <h2 className="mt-2">Saldo em conta: {currencyFormat(balance)}</h2>
          <h2 className="mt-2">Saldo na Iugu: {currencyFormat(balanceIugu)} | Diferença: {currencyFormat(balanceIugu - balance)}</h2>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <Skeleton isActive={loading} repeat={3} space={4} />
              {!loading && (
                <>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          E-mail
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Saldo
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th> */}
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {accounts.map((account) => (
                        <tr key={account.email}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="ml-4 w-64">
                                <div className="font-medium text-gray-900 truncate">{account.name}</div>
                                <div className="text-gray-500">{documentFormatted(account.document)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">{account.email}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">{currencyFormat(account.balance)}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className={`inline-flex rounded-full bg-${getStatus(account.status).color}-100 px-2 text-xs font-semibold leading-5 text-${getStatus(account.status).color}-800`}>
                              {getStatus(account.status).label}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex">
                            {
                              (getStatus(account.status).label === 'Aguardando análise' || getStatus(account.status).label === 'Aguardando endereço' || getStatus(account.status).label === 'Aguardando documentos') && (
                                <>
                                  <button type="button" onClick={() => handleApprove(account.id, account.document)} className="border-none cursor-pointer">
                                    <CheckIcon className="w-5 h-5 text-green-500 hover:text-green-800 mr-2" strokeWidth={3} />
                                  </button>

                                  <button type="button" onClick={() => handleReject(account.id)} className="border-none cursor-pointer">
                                    <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-800 mr-2" />
                                  </button>
                                </>
                              )
                            }

                            <Link to={`/accounts/${account.id}`} className="text-indigo-500 hover:text-indigo-800">
                              <EyeIcon className="h-5 w-5" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Total de {meta.total_items} contas
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <span
                        onClick={handlePrevious}
                        className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Anterior
                      </span>
                      <span
                        onClick={handleNext}
                        className="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Próximo
                      </span>
                    </div>
                  </nav>
                </>

              )}
              <ApproveModal open={open} setOpen={setOpen} setResponsibleName={setResponsibleName} setResponsibleDocument={setResponsibleDocument} finalizeApprove={finalizeApprove} />
              <RejectModal open={openReject} setOpen={setOpenReject} setReject={setReject} finalizeReject={finalizeReject} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}